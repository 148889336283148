<template>
  <SplitPage v-if="authorization">
    <template #header>
      <AuthorizationBreadcrumb :active-step="4"/>
    </template>

    <template #left-top>
      <BackToAuthorizationsIndex/>
    </template>

    <template #left-content>
      <div class="authorization-heading">
        <div class="authorization-number">
          Autorisation n°{{ authorization.id }}
        </div>
        <div class="authorization-cities">
          {{ authorization.city_start.toUpperCase() }} - {{ authorization.city_end.toUpperCase() }}
        </div>
      </div>
      <div v-if="showValidateButton && !canValidateAuthorization"
           class="alert alert-warning"
           role="alert">
        Seul un manager peut valider une autorisation
      </div>
      <AuthorizationSummary :authorization="authorization"/>
      <div class="actions">
        <button @click="edit"
                class="btn btn-block btn-outline-secondary">
          Modifier les infos
        </button>
        <button v-if="showValidateButton && canValidateAuthorization"
                @click="validate"
                :disabled="validateButtonDisabled"
                title="Le trajet tracé n'a pas été terminé"
                class="btn btn-block btn-success">
          Valider l'autorisation
        </button>
      </div>
    </template>

    <template #right-content>
      <AuthorizationMap :center="center"/>
    </template>
  </SplitPage>
</template>

<script>
import SplitPage from '@/components/SplitPage'
import BackToAuthorizationsIndex from '@/components/BackToAuthorizationsIndex'
import AuthorizationBreadcrumb from '@/components/AuthorizationBreadcrumb'
import AuthorizationSummary from '@/components/AuthorizationSummary'
import AuthorizationMap from '@/components/AuthorizationMap'
import { mapState, mapActions } from 'vuex'
import geojsonExtent from '@mapbox/geojson-extent'
import geojsonMerge from '@mapbox/geojson-merge'

export default {
  name: 'AuthorizationsValidatePage',
  components: {
    SplitPage,
    BackToAuthorizationsIndex,
    AuthorizationBreadcrumb,
    AuthorizationSummary,
    AuthorizationMap
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      authorization: state => state.authorization.authorization,
      steps: state => state.authorization.steps
    }),
    center () {
      const steps = this.steps
      steps.forEach((item, index, all) => {
        all[index] = geojsonExtent.bboxify(item)
      })
      return geojsonExtent.bboxify(geojsonMerge.merge(steps)).bbox
    },
    showValidateButton () {
      return ['ongoing input', 'waiting for validation'].includes(this.authorization.status)
    },
    validateButtonDisabled () {
      return this.showValidateButton && this.authorization.status === 'ongoing input'
    },
    canValidateAuthorization () {
      return ['manager', 'admin'].includes(this.user.user.access)
    }
  },
  methods: {
    ...mapActions({
      getAuthorization: 'authorization/getAuthorization',
      getSteps: 'authorization/getSteps',
      resetState: 'authorization/resetState',
      validateAuthorization: 'authorization/validateAuthorization'
    }),
    edit () {
      this.$router.push({
        name: 'authorizations.edit',
        params: {
          id: this.$route.params.id
        }
      })
    },
    validate () {
      if (this.validateButtonDisabled) return
      this.validateAuthorization().then(() => {
        this.$router.push({
          name: 'authorizations.show',
          params: {
            id: this.$route.params.id
          }
        })
      })
    }
  },
  mounted () {
    this
      .getAuthorization(this.$route.params.id)
      .then(() => this.getSteps(this.$route.params.id))
      .catch(() => this.$router.push({ name: '404' }))
  },
  beforeDestroy () {
    this.resetState()
  }
}
</script>

<style scoped lang="scss">
  .authorization-heading {
    margin-bottom: 60px;
    font-size: 25px;
    line-height: 1.1;
    text-align: center;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 40px;
      font-size: 18px;
    }
  }

  .authorization-number {
    margin-bottom: 10px;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 8px;
    }
  }

  .authorization-cities {
    font-weight: $font-weight-medium;
    line-height: 1;
    font-size: 24px;

    @media (max-width: $breakpoint-md) {
      font-size: 17px;
    }
  }

  .actions {
    margin-top: 80px;
  }
</style>
